<template>
  <nav class="navbar" style="position: sticky;">
      <div class="logo" style="color:#333; background-color:white; padding: 0 0.4%;; margin-left: 2%; font-size: 20px;  font-weight: 700;">Katz With A K</div>
      <ul class="nav-links">
        <li><a href="#events">Events</a></li>
        <li><a href="#video">Video</a></li>
        <li><a href="#music">Music</a></li>
        <li><a href="#news">News</a></li>
      </ul>
    </nav>
  <section class="section section__hero in-view" style="margin-top:-2.5%;">
      <div class="section__hero__bg">
          <div class="section__hero__bg__back rellax" style="background-color: transparent;">
  <img :src="backgroundImage" alt="background" style="display:block; width:100%; height: 100%;" />
  
</div>

          <div class="section__hero__bg__front" style="position: absolute; width: 100%; height: 100%;">
            <div class="static-layer"></div>
            <div class="smoke-container">
        <img class="smoke" src="@/assets/images/smoke.png" alt="smoke">
        <img class="smoke" src="@/assets/images/smoke.png" alt="smoke">
        <img class="smoke" src="@/assets/images/smoke.png" alt="smoke">
    </div>
              <div class="antenna">
                  <div class="glowing-flash"></div>
              </div>
              <img
  id="tvDial"
  src="@/assets/images/Dial.png"
  alt="dial"
  style="position: absolute; cursor: pointer; width: 7.30vw; height: 7.30vw; transform-origin: center; top: 43.0%; left: 64.2%;"
  :style="{ transform: `rotate(${currentAngle}deg)` }"
  @click="rotateDial"
/>

              <div class="vcr-text" style="position: relative; top: 83.8%; left: 46.9%;">Katz With A K</div>
              <img src="@/assets/images/Living_Room_2.png" alt="foreground" style="display:block; width:100%; height:100%; margin-top: 1%;" />
          </div>
      </div>
  </section>

  <section id="events" class="content-sections">
    <div class="content-wrapper">
    <h2 class="section-title">Events</h2>
    <div class="event-card">
      <img src="@/assets/images/rosa.jpg" alt="Event Image" class="event-image">
      <div class="event-details">
        <h3 class="event-title">Rosa Festival</h3>
        <p class="event-date">Date: July 7-10, 2023</p>
        <p class="event-location">Location: THE PEAK DISTRICT, DERBYSHIRE</p>
        <p class="event-description">Born during lockdown, Rosa Festival was forged in Sheffield by a mutual passion for soundsystem culture. Amplified by the frustration of social restrictions and the potential destruction of the events industry, Rosa set out to keep the party alive.</p>
        <button style="margin-right: 2%;"><a style="color:#333" href="https://rosafestival.co.uk/">More Info</a></button>
        <button style="margin-left: 2%;"><a href="https://rosafestival.co.uk/tickets">Buy Tickets</a></button>
      </div>
    </div>
  </div>
  </section>
  <section id="video" class="content-sections">
    <div class="content-wrapper">
        <h2 class="section-title">Videos</h2>
        <div class="video-list" style="display: flex; flex-wrap: wrap;">

            <!-- Video 1 -->
            <div class="video-item" style="flex: 1 1 100px; margin: 10px;">
                <a href="https://www.youtube.com/watch?v=2cgkO0xmNLk" target="_blank">
                    <div class="thumbnail" style="background-image: url('https://img.youtube.com/vi/2cgkO0xmNLk/0.jpg'); padding-top: 56.25%; background-size: cover;"></div>
                    <h3 class="video-title">Katz With A K - Loud Mouth</h3>
                </a>
            </div>

            <!-- Video 2 -->
            <div class="video-item" style="flex: 1 1 100px; margin: 10px;">
                <a href="https://www.youtube.com/watch?v=7AEF5sLbNVI" target="_blank">
                    <div class="thumbnail" style="background-image: url('https://img.youtube.com/vi/7AEF5sLbNVI/0.jpg'); padding-top: 56.25%; background-size: cover;"></div>
                    <h3 class="video-title">Katz with a K - UKHH Bars - Nozstock festival</h3>
                </a>
            </div>

            <!-- Video 3 -->
            <div class="video-item" style="flex: 1 1 100px; margin: 10px;">
                <a href="https://www.youtube.com/watch?v=tTwGrmtZ1yA" target="_blank">
                    <div class="thumbnail" style="background-image: url('https://img.youtube.com/vi/tTwGrmtZ1yA/0.jpg'); padding-top: 56.25%; background-size: cover;"></div>
                    <h3 class="video-title">Katz With A K - Wine Cave Fundraiser (Prod. Phoneutrian)</h3>
                </a>
            </div>

            <!-- Video 4 -->
            <div class="video-item" style="flex: 1 1 100px; margin: 10px;">
                <a href="https://www.youtube.com/watch?v=5tvmG05n0P0" target="_blank">
                    <div class="thumbnail" style="background-image: url('https://img.youtube.com/vi/5tvmG05n0P0/0.jpg'); padding-top: 56.25%; background-size: cover;"></div>
                    <h3 class="video-title">Katz With A K - Sacred Text (Produced by Phoneutrian)</h3>
                </a>
            </div>

            <!-- Add more video items here -->

        </div>
    </div>
</section>
<section id="music" class="content-sections">
  <div class="content-wrapper">
    <h2 class="section-title">Music</h2>
    <div class="new-releases">
      <h3 class="section-subtitle">New Releases</h3>
      <div class="album-list" style="display: flex; flex-wrap: wrap;">
        <!-- Album item 1 -->
        <div class="album-item" style="margin: 10px;">
          <div class="album-cover">
            <img src="https://f4.bcbits.com/img/a3823072989_10.jpg" alt="Album Cover" class="cover-image">
          </div>
          <div class="album-info">
            <h4 class="album-title">Energetics</h4>
            <p class="album-artist">Various Artists</p>
            <p class="album-artist">May 16, 2022</p>
            <div class="music-links">
              <a href="https://open.spotify.com/album/6CKCuLSRtke14CNG0IMK4A?utm_medium=share&utm_source=linktree" class="spotify-icon"><img src="@/assets/images/Spotify.png" alt="Spotify"></a>
              <a href="https://geo.music.apple.com/gb/album/_/1085515950?app=music&at=1000lHKX&ct=linktree_http&itscg=30200&itsct=lt_m&ls=1&mt=1" class="spotify-icon"><img src="@/assets/images/Apple-Music.png" alt="Apple Music"></a>
              <a href="https://interworldmedia.bandcamp.com/album/energetics" class="soundcloud-icon"><img src="@/assets/images/band-camp.png" alt="Bandcamp"></a>
              <a href="https://listen.tidal.com/album/285981669" class="soundcloud-icon"><img src="@/assets/images/tidal.png" alt="Tidal"></a>
              <a href="https://www.youtube.com/playlist?list=OLAK5uy_mw0Uy-a2fjRtYm8JPv24BDnCVKAOu2G_w" class="soundcloud-icon"><img src="@/assets/images/YouTube.png" alt="YouTube"></a>
              
            </div>
          </div>
        </div>
        <!-- Album item 2 -->
        <div class="album-item" style="margin: 10px;">
          <div class="album-cover">
            <img src="https://f4.bcbits.com/img/a3093124724_16.jpg" alt="Album Cover" class="cover-image">
          </div>
          <div class="album-info">
            <h4 class="album-title">Black Magick and Red Wine</h4>
            <p class="album-artist">Katz With A K</p>
            <p class="album-artist">March 10, 2023</p>
            

            <div class="music-links">
              <a href="https://open.spotify.com/album/3mO17GjKWuJu9lGLUpjXko?utm_medium=share&utm_source=linktree" class="spotify-icon"><img src="@/assets/images/Spotify.png" alt="Spotify"></a>
              <a href="https://katzwithak.bandcamp.com/album/black-magick-and-red-wine" class="soundcloud-icon"><img src="@/assets/images/band-camp.png" alt="BandCamp"></a>
              <a href="https://listen.tidal.com/album/223298691" class="soundcloud-icon"><img src="@/assets/images/tidal.png" alt="Tidal"></a>
              <a href="https://www.deezer.com/album/307404947" class="soundcloud-icon"><img src="@/assets/images/deezer.svg" alt="Deezer"></a>
              <a href="https://play.anghami.com/album/1027245962?refer=linktree" class="soundcloud-icon"><img src="@/assets/images/anghami.png" alt="anghami"></a>
            </div>
          </div>
        </div>
        <!-- More album items -->
        <!-- ... -->
      </div>
    </div>
    <div class="albums">
      <h3 class="section-subtitle"></h3>
      <div class="album-list" style="display: flex; flex-wrap: wrap;">
        
        <!-- More album items -->
        <!-- ... -->
      </div>
    </div>
  </div>
</section>
  <section id="news" class="content-sections">
    <div class="content-wrapper">
    <h2 class="section-title">News</h2>
    <div class="event-card">
      <img src="https://secreteclectic.files.wordpress.com/2022/04/katz-with-a-k-1.jpg" alt="Event Image" class="event-image">
      <div class="event-details">
        <h3 class="event-title">‘The poets life is torture’ | Interview with Katz With A K </h3>
        <p class="event-date">Date: April 30, 2022</p>
        <p class="event-description" style="color:#333">"Katz With A K delivers his raw, electrifying flow on a freshly cooked, and with an IDM touch, instrumental by Phoneutrian, in their debut collaboration, part of their upcoming full album titled Black Magick and Red Wine. Click play and, to quote the artist himself, bask in the chaos and beauty of destruction..."

</p>
        <button><a style="color:#333" href="https://secreteclectic.com/2022/04/30/the-poets-life-is-torture-interview-with-katz-with-a-k/">More Info</a></button>
      </div>
    </div>
    <div class="event-card">
      <img src="@/assets/images/brainscran.png" alt="Event Image" class="event-image">
      <div class="event-details">
        <h3 class="event-title">BRAINSCRAN RECORDS</h3>
        <p class="event-date">Date: September 30, 2023</p>
        <p class="event-description" style="color:#333">"The first whisper on the proverbial grapevine that we heard of Katz was Dr. Syntax describing him as “If Cannibal Ox were from Sheffield, sort of.” If that’s not an urgent invitation to immediately go absorb a soundcloud rapper’s music then water isn’t wet...</p>
        <button><a style="color:#333" href="https://www.brainscran.com/katz-with-a-k">More Info</a></button>
      </div>
    </div>
    
    
  
    <!-- Add more event cards here -->
    </div>
  </section>
  <footer class="footer">
        <div class="footer-content">
            <div class="social-media">
                <a href="https://www.instagram.com/yourprofile" target="_blank">
                    <img src="@/assets/images/instagram.png" alt="Instagram" style="height:50px; margin: 1% 1%;"/>
                </a>
                <a href="https://www.twitter.com/yourprofile" target="_blank">
                    <img src="@/assets/images/twitter.png" alt="Twitter" style="height:50px; margin: 1% 1%;"/>
                </a>
                <a href="https://www.facebook.com/yourprofile" target="_blank">
                    <img src="@/assets/images/facebook.png" alt="Facebook" style="height:50px; margin: 1% 1%;"/>
                </a>
            </div>
            <p style="margin-top:2%">© 2023 Katz With A K. All rights reserved.</p>
            <nav>
                
            </nav>
            <p>Website by <a style="background-image:linear-gradient(3deg,#39007d,#bc329a 40%,#ff6373 80%); color: white;" href="https://pixelcodelab.co.uk"><strong>PixelCodeLab</strong></a></p>
        </div>
    </footer>

</template>











<script>
import '@/assets/css/styles.css';
export default {
  name: 'App',
  data: () => {
		return {
        theme: '',
        currentAngle: 0,
        backgroundImage: require('@/assets/images/nathan_2.png')
    };
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
        
	
	},
	updated() {
		
	},
    methods: {
        rotateDial() {
        this.currentAngle += 40; // You can adjust this value for the rotation increment
        
        const backgroundImages = [
            require('@/assets/images/nathan_2.png'),
            require('@/assets/images/nathan_3.png'),
            require('@/assets/images/nathan_3.png'),
            // ... add more background images here
        ];
        
        // Select a background image based on the current angle
        const index = Math.floor(this.currentAngle / 40) % backgroundImages.length;
        this.backgroundImage = backgroundImages[index];
    }
    }

};
</script>

<style>
@media (max-width: 768px) { 

  .content-wrapper {
  max-width: 100% !important;
  padding: 20px;
}
  ul.nav-links {
    padding:2%;
  }

  .event-card {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
    flex-direction: column;
    padding: 6%;
}
.event-image {
  width: 200px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 0px !important;
  margin-bottom: 10%;
}
}

.section__hero {
  margin-top: -11.5%;

}
.section__hero__bg {
    position: relative;
    height: auto;
    transform: scale(2.2);
    margin: 27% 0px;
}

@media (min-width: 768px) { 
  .section__hero {
  margin-top: -2.5% !important;

}
.section__hero__bg {
    position: relative;
    height: auto;
    transform: scale(1.0);
    margin: 0% 0px;
}

}


h3.video-title {
  font-size: 1.25rem;
  color: black;
  padding: 2%
}


.content-wrapper {
  max-width: 80%;
  padding: 20px;
}

.section-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.section-subtitle {
  font-size: 18px;
  margin-bottom: 10px;
}


.event-card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.event-image {
  width: 200px;
  height: auto;
  object-fit: cover;
  border-radius: 18px;
  margin-right: 20px;
  box-shadow: rgb(0 0 0 / 20%) 10px 10px 34px;
}

.event-details {
  flex: 1;
}

.event-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.event-date,
.event-location {
  font-size: 14px;
  margin-bottom: 5px;
}

.event-description {
  font-size: 14px;
  color: #fff;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
}

.video-item {
  width: calc(33.33% - 20px);
  margin: 10px;
  position: relative;
}

.thumbnail {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 20%) 10px 10px 34px;
}

.video-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  overflow: hidden;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.video-item:hover .thumbnail {
  opacity: 0.7;
}

.video-item:hover .video-modal {
  display: block;
}


.album-list {
  display: flex;
  flex-wrap: wrap;
}

.album-item {
  margin: 10px;
  position: relative;
}

.album-cover {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 18px;
    box-shadow: rgb(0 0 0 / 20%) 10px 10px 34px;
}

.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.album-info {
  padding-top: 10px;
}

.album-title {
  font-size: 1.25rem;
  margin-bottom: 5px;
}

.album-artist {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}

.music-links {
  
  justify-content: center;
}

.music-links a {
  display: inline-block;
  margin-right: 10px;
}

.music-links img {
  width: 24px;
  height: 24px;
}

.spotify-icon img,
.soundcloud-icon img {

}

.spotify-icon:hover img,
.soundcloud-icon:hover img {
  filter: none;
}
.content-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .content-sections {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 2px 20vw;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

#events {
  background-image: linear-gradient(to left, rgb(242, 112, 156), rgb(255, 148, 114));
  margin-top: 30px;
  
}

#video {
  background-image: linear-gradient(to left, rgb(50 100 136), rgb(82 195 207));
  margin-top: 30px;
  
}

#music {
  color:white;
  background-image: linear-gradient(to right top, #e94560, #bf3a79, #883c83);
  margin-top: 30px;
}

#news {
  background-color: #d5d5d5;
  margin-top: 30px;
}

#app {
  position: relative;
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000; /* Set a higher z-index to ensure navbar stays on top */
  background: #333;
  padding: 10px 20px;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center; /* Center the navigation items */
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.layered-images {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background-image,
.foreground-image,
.static-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background-image img,
.foreground-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-image {
  z-index: 1;
}

.static-layer {
  background: repeating-radial-gradient(#000 0 0.0001%, #fff 0 0.0002%) 50% 0/2500px 2500px, repeating-conic-gradient(#000 0 0.0001%, #fff 0 0.0002%) 60% 60%/2500px 2500px;
  background-blend-mode: difference;
  animation: static-animation 0.2s infinite alternate;
  pointer-events: none;
  z-index: -1;
  opacity: 0.1;
}

.static-layer.reveal {
  mask-position: 100% 100%;
}

@keyframes static-animation {
  100% {
    background-position: 50% 0, 60% 50%;
  }
}

.foreground-image {
  z-index: 9999;
}

.vcr-text {
  
  position: absolute;
    bottom: -72.9%;
    left: 46.9%;
    z-index: 1030z;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Courier New', monospace;
    font-size: 11px;
    height: 2.1%;
    width: 6.4vw;
    padding-top: 0.2%;
    color: #00ff00;
    animation: flicker-animation 1s infinite;
    background: repeating-linear-gradient( to bottom, rgb(197 71 71) 0, #d39cefb8 1px, #252525 1px, transparent 2px );
    filter: contrast(1.5);
    letter-spacing: 2px;
    padding: 2.5px 0px;
}

@keyframes flicker-animation {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.8;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
h1 {
	font-size: 4rem;
}
.font-general-medium {
            text-align: left;
			color:#fff
        }
@media (max-width: 767px) {
        h1 {
            line-height: 1.2; /* Adjust the line-height value as needed */
			font-size: 2rem;
			text-align: left;
        }
		.font-general-medium {
            text-align: left;
        }
		.flex-col {
			flex-direction: column-reverse;
		}
    }
	
.section__hero__bg__back {
    background-color: transparent !important;
    transform: none !important;
	background-color: transparent !important;
    transform: none !important;
}
.antenna {
  position: relative;
  z-index: 99999999;
  top: 19.4%;
  left: 14.8%;
  /* Other styles for the antenna */
}
.glowing-flash {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  opacity: 0;
  box-shadow: 0 0 5px 5px rgb(255, 255, 255);
  animation: glow 5s infinite;
}

.antenna::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  opacity: 0;
  box-shadow: 0 0 5px 5px red;
  animation: glow 5s infinite;
}

@keyframes glow {
  0% {
    opacity: 0;
    box-shadow: 0 0 5px 2px red;
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 10px 5px red;
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 5px 2px red;
  }
}
.neon-light-1 {
    font-size: 24px;
    color: #ffd7006b;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation 1.5s infinite alternate;
    z-index: 5;
	position: absolute;
    top: 30%; /* Adjust this as needed */
    left: 67.5%; /* Adjust this as needed */
	writing-mode: vertical-lr;
	transform: skewY(10deg)
}
.neon-light-2 {
	font-size: 20px;
    color: #04104df2;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation-99c0ec1a 1.5s infinite alternate;
    z-index: 5;
    position: absolute;
    top: 43%;
    left: 76.5%;
    transform: skewY(5deg);
}
@media (max-width: 768px) {
  /* Adjust the maximum width as per your needs */
  .neon-light-1 {
	font-size: 10px;
    color: #ffd7006b;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation-99c0ec1a 1.5s infinite alternate;
    z-index: 5;
    position: absolute;
    top: 30%;
    left: 66.5%;
    writing-mode: vertical-lr;
    transform: skewY(10deg);
}
.neon-light-2 {
    font-size: 8px;
    color: #04104df2;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation-99c0ec1a 1.5s infinite alternate;
    z-index: 5;
    position: absolute;
    top: 43%;
    left: 76.5%;
    transform: skewY(5deg);
}
  }


@keyframes flickerAnimation {
    0% {
        opacity: 1;
        text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    }
    100% {
        opacity: 0.7;
        text-shadow: none;
    }
}

.section__hero {
    position: relative;
    overflow: hidden;
    
}

@media (max-width: 768px) {
  /* Adjust the maximum width as per your needs */
  .section__hero {
    height: auto; /* Set height to auto for mobile devices */
  }
}

.section__hero__bg {
    position: relative;
    height: auto;
}

.section__hero__bg__front, .section__hero__bg__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.section__hero__bg__front {
    z-index: 2;
    position: absolute;
}

.section__hero__bg__back {
    z-index: 1;
	position: relative;
}







.buttons {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.buttons button {
  border: none;
  background: none;
  padding: 0;
  margin: 0 5px;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.buttons button:focus {
  background-color: rgba(255, 255, 255, 0.3);
}

.tv-button {
  background-color: #333;
}

/* Media Query for Mobile */
@media screen and (max-width: 480px) {
  .foreground-image img {
    content: url("@/assets/images/Living_Room_Mob.png");
  }

  .background-image img {
    content: url("@/assets/images/nathan_mob.png");
  }
}
.smoke-container {
    position: absolute;
    bottom: 26%; /* Adjust this value */
    left: 31%; /* Adjust this value */
}

.smoke {
    position: absolute;
    width: 100px; /* Adjust as needed */
    opacity: 1;
    animation: smoke-animation 15s infinite;
}

@keyframes smoke-animation {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0.3;
    }
    50% {
        transform: translateY(-50px) scale(1.2);
        opacity: 0.1;
    }
    100% {
        transform: translateY(-100px) scale(1.5);
        opacity: 0;
    }
}
.footer {
    background-color: #202b35;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
    margin-top: 2%;
  
}

.footer-content p {
    margin: 0;
}

.footer-content nav ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
}

.footer-content nav ul li {
    display: inline-block;
    margin: 0 15px;
}

.footer-content nav ul li a {
    color: #ffffff;
    text-decoration: none;
}
</style>
